<template>
    <v-container>
        <NavButton class="mb-4" />
        <OfferListComponent 
            :requestId="requestId" 
            :productId="productId" 
            :timer="timer"
            :productKind="productKind"
            :isEditAction="true"
            @disponibilidad-change="handleDisponibilidadChange" 
        />
    </v-container>
</template>
  
<script>
import { OfferListComponent } from '@/components/catalogoEnLinea/purchaseFlow/'
import { createLoadable, toggleLoadable, setLoadableResponse } from '@/utils/loadable.js';
import { mapState } from 'vuex';
import { transformDuration } from '@/utils/countdown';
import { NavButton } from '@/components/utils';
const validTimerStatus = ['ticking', 'expired', 'empty-request'];

export default {
    name: 'OfferListComponent.vue',
    components: {
        OfferListComponent,
        NavButton,
    },
    data: () => ({
        requestId: null,
        productId: null,
        requestToEdit: createLoadable(null),
        product: createLoadable(null),
        disponibilidad: 0,
        timer: {
            status: 'initial',
            tick: null,
        },
    }),
    computed: {
        ...mapState(["userInfo", "serverTime"]),
        productKind() {
            return this.product.data?.id_tipo_producto;
        },
        hasActiveRequest() {
            return Boolean(this.requestToEdit.data);
        },
        request() {
            return this.requestToEdit.data?.solicitud;
        },
        cartOffers() {
            return this.requestToEdit.data?.productosActualizados ?? [];
        },
        remainingTime() {
            if (!this.request || !this.serverTime) return null;

            const expiracion = moment(this.request.expiracion_carrito);
            const diff = expiracion.diff(this.serverTime);
            const duracion = moment.duration(diff);

            return duracion;
        },
    },
    methods: {
        setTimerTick(tick) {
            this.timer.tick = tick;
        },
        tick(duration) {
            if (this.timer.status !== 'ticking') this.setTimerStatus('ticking');
            
            this.setTimerTick(transformDuration(duration));
        },
        setTimerStatus(status) {
            if (!validTimerStatus.includes(status)) return;
            this.timer.status = status;
        },
        handleExpired() {
            this.setTimerTick(null);
            this.setTimerStatus('expired');
        },
        handleTimerStart() {
            this.setTimerStatus('ticking');
        },
        async fetchRequest() {
            toggleLoadable(this.requestToEdit);
            const { data } = await this.services.PurchaseRequest.getRequestById(this.requestId);
            setLoadableResponse(this.requestToEdit, data);
        },
        async fetchProduct() {
            toggleLoadable(this.product);
            const { data } = await this.services.Product.getProductById(this.productId);
            setLoadableResponse(this.product, data);
        },
        handleDisponibilidadChange(value) {
            this.disponibilidad = value;
        },
    },
    watch: {
        remainingTime(value) {
            // No hay carrito activo
            if (value === null) {
                if (this.timer.status !== 'empty-request') this.setTimerStatus('empty-request');

                return;
            }

            // El tiempo del carrito aun esta vigente
            if (value > 0) return this.tick(value);

            // El tiempo del carrito ha expirado
            this.handleExpired();
        },
    },
    created() {
        const id = this.$route.params.productId;
        this.productId = Number(id);
        this.fetchProduct();

        this.requestId = this.$route.params.requestId;
        this.fetchRequest();
    },
    beforeDestroy() {
        Countdown.getInstance().clear();
    },
}
</script>
  